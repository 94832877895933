import React from "react";
import {
	PageLayout,
	Link,
	PageMargins,
	TypographyV2,
} from "@components";
import * as styles from "./index.module.scss";
import "./index.scss";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";

type Content = {
	date?: string;
	intro: string[];
	body: {
		title: string;
		paragraph: string[];
	}[];
};

type PolicyPageProps = {
	pageName: "privacy-policy" | "terms-of-service" | "msa" | "dsa";
	title: string;
	content: Content;
	withTableOfContents?: boolean;
	preBody?: React.ReactNode;
};

export default function PolicyPage({
	title,
	content,
	withTableOfContents = false,
	preBody,
	pageName,
}: PolicyPageProps) {

	const { date, intro, body } = content;

	const renderSectionTitle = (title: string) => (
		<TypographyV2
			variant="HEADING_5"
			color="text-secondary"
			className={styles.sectionTitle}
		>
			{title}
		</TypographyV2>
	);

	const renderParagraphs = (keyPrefix: string, paragraphs: string[]) => (
		<TypographyV2 variant="BODY_TEXT_LARGE" color="text-secondary">
			{paragraphs.map((paragraph, i) => (
				<div
					className={styles.policyParagraph}
					key={keyPrefix + i}
					dangerouslySetInnerHTML={{ __html: paragraph }}
				></div>
			))}
		</TypographyV2>
	);

	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: pageName }}>
			<BackgroundFadeContainer type="dpaBlue" bodyClass={styles.header}>
				<TypographyV2 variant="HEADING_1" color="ui-01" inline center>
					<span style={{ textAlign: "center", display: "block" }}>
						{title}
					</span>
				</TypographyV2>
			</BackgroundFadeContainer>

			<PageMargins fullWidth={false}>
				<div className={styles.policyPage}>
					{date && (
						<TypographyV2 variant="BODY_TEXT_LARGE" color="text-helper">
							<p>Last updated: {date}</p>
						</TypographyV2>
					)}

					<div className={styles.section}>
						{renderParagraphs("intro", intro)}
					</div>

					{withTableOfContents && (
						<div className={styles.tableOfContent}>
							{renderSectionTitle("Table of Contents")}
							{body.map((policy, index) => (
								<Link
									link="PRIVACY_POLICY"
									suffix={"/#" + (index + 1)}
									key={index}
								>
									<TypographyV2
										variant="BODY_TEXT_LARGE"
										color="text-secondary"
										className={styles.link}
									>
										{policy.title}
									</TypographyV2>
								</Link>
							))}
						</div>
					)}

					{preBody}
					<div className={styles.section}>
						{body.map(({ title, paragraph }, i) => (
							<div className={styles.mainContent} key={title + i}>
								<div
									className={styles.linkAnchor}
									id={i + 1 + ""}
								></div>
								<div className={styles.paragraphTitle}>
									{renderSectionTitle(title)}
								</div>
								{renderParagraphs("body", paragraph)}
							</div>
						))}
					</div>
				</div>
			</PageMargins>
			{/* </BackgroundShapes> */}
		</PageLayout>
	);
}
