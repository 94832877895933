// extracted by mini-css-extract-plugin
export var BODY = "index-module--BODY--7Wk7P";
export var BODY__newLayout = "index-module--BODY__newLayout--tMACA";
export var BUTTON = "index-module--BUTTON--NdtNh";
export var BUTTON__newLayout = "index-module--BUTTON__newLayout--EhuC6";
export var CAPTION = "index-module--CAPTION--hdZ7I";
export var CAPTION__newLayout = "index-module--CAPTION__newLayout--T1vLd";
export var DESCRIPTION = "index-module--DESCRIPTION--jZxPj";
export var DESCRIPTION__newLayout = "index-module--DESCRIPTION__newLayout--tvN3e";
export var FOOTER = "index-module--FOOTER--vPfb4";
export var FOOTERBOLD = "index-module--FOOTERBOLD--pBEWi";
export var FOOTER__newLayout = "index-module--FOOTER__newLayout--sCE3V";
export var HEADER1 = "index-module--HEADER1--A7i5s";
export var HEADER1__newLayout = "index-module--HEADER1__newLayout--sv3hW";
export var HEADER2 = "index-module--HEADER2--dUa7J";
export var HEADER2__newLayout = "index-module--HEADER2__newLayout--0mu7g";
export var HEADER3 = "index-module--HEADER3--XTyY+";
export var HEADER3__newLayout = "index-module--HEADER3__newLayout--vb7co";
export var HEADER4 = "index-module--HEADER4--lSk1r";
export var HEADER4__newLayout = "index-module--HEADER4__newLayout--9ak0h";
export var HERO = "index-module--HERO--CkSaY";
export var HERO__newLayout = "index-module--HERO__newLayout--xXW8+";
export var QUOTE = "index-module--QUOTE--cRjJe";
export var SUBHEADERDESC = "index-module--SUBHEADERDESC--QVGcL";
export var SUBHEADERDESCBOLD = "index-module--SUBHEADERDESCBOLD--VhqUK";
export var SUBHEADERDESC__newLayout = "index-module--SUBHEADERDESC__newLayout--7zchP";
export var SUBTITLE = "index-module--SUBTITLE--O2jvV";
export var SUBTITLE__newLayout = "index-module--SUBTITLE__newLayout--qLqAU";
export var center = "index-module--center--1SXOF";
export var header = "index-module--header--nz792";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--yxXtK";
export var link = "index-module--link--8osO4";
export var linkAnchor = "index-module--linkAnchor--GA03k";
export var mainContent = "index-module--mainContent--STcNq";
export var pageTitle = "index-module--pageTitle--avvU+";
export var paragraphTitle = "index-module--paragraphTitle--Uoyig";
export var policyPage = "index-module--policyPage--jUNn0";
export var policyParagraph = "index-module--policyParagraph--GA0Yu";
export var section = "index-module--section--qDPcW";
export var sectionTitle = "index-module--sectionTitle--SaJZK";
export var subtitle = "index-module--subtitle--tdWMz";
export var tableOfContent = "index-module--tableOfContent--Q8zkV";